<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add warehouse</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row ma-0 pa-0">
          <div
            class="col-12 col-sm-6 my-0 py-0"
            v-if="serverData.organizations"
          >
            <v-autocomplete
              v-model="formData.organization_id"
              label="Organization"
              item-text="text"
              item-value="index"
              :items="serverData.organizations"
              clearable
              outlined
              dense
              :error-messages="organization_idErrors"
              @input="$v.formData.organization_id.$touch()"
              @blur="$v.formData.organization_id.$touch()"
            />
          </div>
          <div
            class="col-12 col-sm-6 my-0 py-0"
            v-if="serverData.organizations || serverData?.divisions"
          >
            <v-autocomplete
              v-model="formData.division_id"
              label="Division"
              item-text="text"
              item-value="index"
              :items="divisions"
              clearable
              outlined
              dense
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-autocomplete
              v-model="formData.warehouse_type_id"
              label="Warehouse type"
              item-text="text"
              item-value="index"
              :items="serverData.warehouse_types"
              clearable
              outlined
              dense
              :error-messages="warehouse_type_idErrors"
              @input="$v.formData.warehouse_type_id.$touch()"
              @blur="$v.formData.warehouse_type_id.$touch()"
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.system_code"
              label="System code"
              clearable
              outlined
              dense
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.warehouse_name"
              label="Warehouse name"
              clearable
              outlined
              dense
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-autocomplete
              v-model="formData.timezone"
              label="Timezone"
              item-text="text"
              item-value="index"
              :items="serverData.timezones"
              clearable
              outlined
              dense
              :error-messages="timezoneErrors"
              @input="$v.formData.timezone.$touch()"
              @blur="$v.formData.timezone.$touch()"
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.company_code"
              label="Company code"
              clearable
              outlined
              dense
              :error-messages="company_codeErrors"
              @input="$v.formData.company_code.$touch()"
              @blur="$v.formData.company_code.$touch()"
            />
          </div>
          <div class="col-12 my-0 py-0">
            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              outlined
              dense
              hide-details
            />
          </div>
          <div class="col-12 col-lg-5 d-flex align-center py-0 my-0 h-65px">
            <h3 class="pl-2 h6 font-weight-regular">Status</h3>
            <div class="col-4 col-sm-2"></div>
            <v-switch :label="statusLabel" v-model="formData.status"></v-switch>
          </div>
          <div class="col-12 col-lg-5 d-flex align-center py-0 my-0 h-65px">
            <h3 class="pl-2 h6 font-weight-regular">Default</h3>
            <div class="col-4 col-sm-2"></div>
            <v-switch v-model="formData.is_default"></v-switch>
          </div>
          <input-group label="Address">
            <div class="col-12 col-sm-6 my-0 py-0">
              <v-autocomplete
                :disabled="
                  serverData.organizations && !this.formData.organization_id
                "
                @change="onCountryChange"
                v-model="$v.formData.country_id.$model"
                label="Country"
                item-text="text"
                item-value="index"
                :items="serverData.countries"
                clearable
                outlined
                dense
                :error-messages="
                  serverData.organizations && !this.formData.organization_id
                    ? ['Please, select organization!']
                    : country_idErrors
                "
                @input="$v.formData.country_id.$touch()"
                @blur="$v.formData.country_id.$touch()"
              />
            </div>
            <div class="col-12 col-sm-6 my-0 py-0">
              <v-autocomplete
                @change="onStateChange"
                :disabled="isStatesLoaded"
                v-model="$v.formData.state_id.$model"
                label="State"
                item-text="title"
                item-value="id"
                :items="serverData.states"
                clearable
                outlined
                dense
                :error-messages="state_idErrors"
                @input="$v.formData.state_id.$touch()"
                @blur="$v.formData.state_id.$touch()"
              />
            </div>
            <div class="col-12 col-sm-6 my-0 py-0">
              <v-autocomplete
                @change="onCityChange"
                :disabled="isCitiesLoaded"
                v-model="$v.formData.city_id.$model"
                label="City"
                item-text="title"
                item-value="id"
                :items="serverData.cities"
                clearable
                outlined
                dense
                :error-messages="city_idErrors"
                @input="$v.formData.city_id.$touch()"
                @blur="$v.formData.city_id.$touch()"
              />
            </div>
            <div class="col-12 col-sm-6 my-0 py-0">
              <v-autocomplete
                :disabled="isAreasLoaded"
                v-model="formData.area_id"
                label="Area"
                item-text="title"
                item-value="id"
                :items="serverData.areas"
                clearable
                outlined
                dense
              />
            </div>

            <div class="col-12 my-0 py-0">
              <v-text-field
                v-model="formData.address"
                label="Address"
                clearable
                outlined
                dense
              />
            </div>
            <div class="col-12 col-sm-8 my-0 py-0">
              <v-text-field
                v-model="formData.address_2"
                label="Address 2"
                clearable
                outlined
                dense
              />
            </div>
            <div class="col-12 col-sm-4 my-0 py-0">
              <v-text-field
                v-model="formData.postal_code"
                label="Postal code"
                clearable
                outlined
                dense
              />
            </div>
            <div class="col-12 col-sm-4 my-0 py-0">
              <v-text-field
                v-model="formData.contact_name"
                label="Contact name"
                clearable
                outlined
                dense
                hide-details
              />
            </div>

            <div class="col-12 col-sm-4 my-0 py-0">
              <v-text-field
                v-model="formData.contact_phone"
                label="Contact phone"
                clearable
                outlined
                dense
                hide-details
              />
            </div>
            <div class="col-12 col-sm-4 my-0 py-0">
              <v-text-field
                v-model="formData.contact_email"
                label="Contact email"
                clearable
                outlined
                dense
                hide-details
              />
            </div>
          </input-group>
        </div>

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { GET_WAREHOUSES } from "@/core/services/store/warehouses.module";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import InputGroup from "@/own/components/fulfillment/skus/InputGroup.vue";

export default {
  name: "AddWarehouse",
  mixins: [validationMixin],
  components: { InputGroup },
  validations() {
    return {
      formData: {
        country_id: { required },
        state_id: { required },
        city_id: { required },
        warehouse_type_id: { required },
        organization_id: {
          required: requiredIf(() => {
            return !!this.serverData.organizations;
          }),
        },
        company_code: { required },
        timezone: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      type: "warehouse",
      warehouse_type_id: null,
      organization_id: null,
      system_code: null,
      country_id: null,
      warehouse_name: null,
      description: null,
      address: null,
      address_2: null,
      postal_code: null,
      city_id: null,
      state_id: null,
      area_id: null,
      contact_name: null,
      surname: null,
      contact_phone: null,
      contact_email: null,
      status: null,
      is_default: false,
      company_code: null,
      timezone: null,
      division_id: null,
    },
  }),
  computed: {
    divisions() {
      let divs = [];
      if (this.serverData?.organizations) {
        if (this.formData.organization_id) {
          divs = this.serverData.organizations.find(
            (org) => org.index == this.formData.organization_id
          ).divisions;
        }
      } else if (this.serverData?.divisions) {
        divs = this.serverData.divisions;
      }
      return divs;
    },
    statusLabel() {
      return this.formData.status ? "Active" : "In active";
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
    isStatesLoaded: function () {
      return !this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData.areas;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },

    country_idErrors: function () {
      return this.handleFormValidation("country_id", this);
    },
    state_idErrors: function () {
      return this.handleFormValidation("state_id", this);
    },
    city_idErrors: function () {
      return this.handleFormValidation("city_id", this);
    },
    warehouse_type_idErrors: function () {
      return this.handleFormValidation("warehouse_type_id", this);
    },
    company_codeErrors: function () {
      return this.handleFormValidation("company_code", this);
    },
    timezoneErrors: function () {
      return this.handleFormValidation("timezone", this);
    },
    organization_idErrors: function () {
      return this.handleFormValidation("organization_id", this);
    },
  },
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    toggleModal() {
      if (!this.dialog) {
        this.loadDataFromServer();
      } else {
        this.resetCreateForm();
        this.serverData = null;
      }
      this.dialog = !this.dialog;
    },
    loadDataFromServer() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { type: "warehouse" };
      ApiService.post(`/warehouse_management/warehouses/create`, data)
        .then((response) => {
          this.serverData = response.data;
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.dialog = false;
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    submitCreateForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let sendData = {
        ...this.formData,
      };
      if (!this.serverData.organizations) {
        sendData.organization_id =
          this.$store.getters.currentUser.data.organization_id;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/warehouses/store", sendData)
        .then(() => {
          swalAdded();
          this.toggleModal();
          this.$store.dispatch(GET_WAREHOUSES).finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();

      this.formData = {
        type: "warehouse",
        warehouse_type_id: null,
        organization_id: null,
        system_code: null,
        country_id: null,
        warehouse_name: null,
        description: null,
        address: null,
        address_2: null,
        postal_code: null,
        city_id: null,
        state_id: null,
        area_id: null,
        contact_name: null,
        surname: null,
        contact_phone: null,
        contact_email: null,
        status: null,
        division_id: null,
        is_default: false,
        company_code: null,
        timezone: null,
      };
    },
    async onCountryChange(val, safe = false) {
      if (val) {
        this.$store.commit(SET_PAGE_LOADING, true);
        let data = { country: val };
        if (this.formData.organization_id) {
          data["organization_id"] = this.formData.organization_id;
        }
        await ApiService.post("/address/states/search", data)
          .then((response) => {
            this.serverData.states = response.data.states;
            if (!safe) {
              this.formData.city_id = null;
              this.formData.area_id = null;
              this.serverData.cities = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            if (!safe) {
              this.formData.city_id = null;
              this.formData.area_id = null;
              this.serverData.cities = null;
              this.serverData.areas = null;
            }

            this.dialog = false;
          });
      }
    },
    async onStateChange(val, safe = false) {
      if (val) {
        this.$store.commit(SET_PAGE_LOADING, true);
        let data = { state: val };
        if (this.formData.organization_id) {
          data["organization_id"] = this.formData.organization_id;
        }
        await ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.serverData.cities = response.data.cities;
            if (!safe) {
              this.formData.area_id = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            if (!safe) {
              this.formData.area_id = null;
              this.serverData.areas = null;
            }
            this.dialog = false;
          });
      }
    },
    async onCityChange(val) {
      if (val) {
        this.$store.commit(SET_PAGE_LOADING, true);
        let data = { city: val };
        if (this.formData.organization_id) {
          data["organization_id"] = this.formData.organization_id;
        }
        await ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.serverData.areas = response.data.areas;
            let i = { ...this.serverData };
            this.serverData = i;
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            this.dialog = false;
          });
      }
    },
  },
};
</script>
